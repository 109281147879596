/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Eventy"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-142s9x6 css-mlpdok --style2 --full pb--60 pt--60" name={"qv2cty7gbkf"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f87b56db01b3408eade42ea431d8ee9f_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f87b56db01b3408eade42ea431d8ee9f_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f87b56db01b3408eade42ea431d8ee9f_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f87b56db01b3408eade42ea431d8ee9f_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f87b56db01b3408eade42ea431d8ee9f_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f87b56db01b3408eade42ea431d8ee9f_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f87b56db01b3408eade42ea431d8ee9f_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f87b56db01b3408eade42ea431d8ee9f_s=3000x_.jpeg);
    }
  
background-position: 49% 59%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Eventy"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sluzby"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left" content={"Uspořádejte u nás"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/df37a0a1460a4a7582444551de72f6b6_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/18947/df37a0a1460a4a7582444551de72f6b6_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/df37a0a1460a4a7582444551de72f6b6_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/df37a0a1460a4a7582444551de72f6b6_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Tiskové konference</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/16d652c6f6354c27bd92b9ddefd64822_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/18947/16d652c6f6354c27bd92b9ddefd64822_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/16d652c6f6354c27bd92b9ddefd64822_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/16d652c6f6354c27bd92b9ddefd64822_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Oslavy</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/24b2b40af3df4bca8122de5318138741_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/18947/24b2b40af3df4bca8122de5318138741_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/24b2b40af3df4bca8122de5318138741_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/24b2b40af3df4bca8122de5318138741_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Firemní večírky</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/fb4245a10cd44006b50c0b38a2adf907_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/18947/fb4245a10cd44006b50c0b38a2adf907_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/fb4245a10cd44006b50c0b38a2adf907_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/fb4245a10cd44006b50c0b38a2adf907_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Firemní snídaně</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/eb6baeb1a5ff4f509239d8df8055bdae_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/18947/eb6baeb1a5ff4f509239d8df8055bdae_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/eb6baeb1a5ff4f509239d8df8055bdae_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/eb6baeb1a5ff4f509239d8df8055bdae_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Křest knihy</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/b0f7d991ea28406aa4fff2d186c9b8c5_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/18947/b0f7d991ea28406aa4fff2d186c9b8c5_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/b0f7d991ea28406aa4fff2d186c9b8c5_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/b0f7d991ea28406aa4fff2d186c9b8c5_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Představení nového produktu</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sluzby"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left" content={"Rádi pro vás připravíme"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/15675a5073bf469597c647b1aa01328c_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":192}} srcSet={"https://cdn.swbpg.com/t/18947/15675a5073bf469597c647b1aa01328c_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/15675a5073bf469597c647b1aa01328c_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/15675a5073bf469597c647b1aa01328c_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: rgb(0, 0, 0);\">Studená | teplá kuchyně</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Přípravíme nejen kanapky a sýrovou mísu, ale i teplé občerstvení.</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: rgb(0, 0, 0);\">Rautové stoly</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Na našich švédských stolech si každý z vašich hostů vybere právě to, na co má zrovna chuť.</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: rgb(0, 0, 0);\">Sweet bar</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Myslíme i na mlsné jazýčky. Sladký bar zpestříme oblíbeným tiramisu, panna cottou nebo třeba domácími koláči.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: rgb(0, 0, 0);\">Přípitek</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Víme, jak začít důležitý event.<br><br></span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: rgb(0, 0, 0);\">Degustace</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Zajišťujeme degustace vín, rumů nebo také párování jídla s vínem.</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: rgb(0, 0, 0);\">Alko | Nealko nápoje</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Koktejly, vína, piva, domácí limonády i čerstvé pomerančové džusy.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"pobocky"} style={{"backgroundColor":"rgba(112,156,159,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(255, 255, 255);\">Naše pobočky</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/18947/eaa01f29ecf64d8e8df2e03f5179aa08_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":713}} srcSet={"https://cdn.swbpg.com/t/18947/eaa01f29ecf64d8e8df2e03f5179aa08_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/eaa01f29ecf64d8e8df2e03f5179aa08_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/eaa01f29ecf64d8e8df2e03f5179aa08_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/eaa01f29ecf64d8e8df2e03f5179aa08_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/eaa01f29ecf64d8e8df2e03f5179aa08_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Václavské náměstí</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(255, 255, 255);\">Dům módy, 1. patro\n<br>Václavské náměstí 804,<br>Praha 110 00 <br><br>+420 234 101 138 <br>info@oliverscoffeecup.cz\n<br><br>Kapacita 80 osob</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Karlín</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(255, 255, 255);\">Futurama Business Park<br>Sokolovská 663/136F,<br>Praha 186 00 <br><br>+420 725 323 325 <br>info@oliverscoffeecup.cz&nbsp;<br><br>Kapacita 30 osob</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":685}} srcSet={"https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"c3elj5bbsd"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Nezávazná poptávka"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Vaše jméno:"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"E-mailová adresa:"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon:"},{"name":"Počet hostů","type":"text","placeholder":"Počet hostů:"},{"name":"Charakter akce","type":"text","placeholder":"Charakter akce:"},{"name":"Datum konání","type":"text","placeholder":"Datum konání:"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Napište nám více informací o vašem eventu:"},{"name":"Odeslat formulář","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60">
          
          <ColumnWrap className="column__flex el--4 pl--0 pr--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Image className="--justify pl--30" alt={""} src={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":144}} srcSet={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">E-MAIL</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">info@oliverscoffeecup.cz</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">TELEFON</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">+420 234 101 138</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">SLEDUJTE NÁS</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\"><a href=\"https://www.facebook.com/oliverscoffeecup.cz\" target=\"_blank\">Facebook<br></a><a href=\"https://www.instagram.com/oliverscoffeecup/\" target=\"_blank\">Instagram</a><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}